import React from "react";
import PropTypes from "prop-types";
import styles from "./list.module.scss";

const List = props => {
  const { heading, listItems, isLarge, isColor } = props;
  return (
    <section className={styles.list}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      <ul
        className={`${styles.listContainer} ${isLarge &&
          styles.large} ${isColor && styles.color}`}
      >
        {listItems.map(item => (
          <li key={item} className={styles.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </section>
  );
};

List.propTypes = {
  heading: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLarge: PropTypes.bool,
  isColor: PropTypes.bool
};

List.defaultProps = {
  heading: null,
  isLarge: false,
  isColor: false
};

export default List;
