import React from "react";
import PropTypes from "prop-types";
import Separator from "./separator";
import styles from "./paragraphs.module.scss";

const Paragraphs = props => {
  const { heading, paragraphs } = props;
  return (
    <section className={styles.paragraphs}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {paragraphs.map((paragraph, index) => (
        <React.Fragment key={paragraph}>
          {index !== 0 && <Separator isColor />}
          <p className={styles.paragraph}>{paragraph}</p>
        </React.Fragment>
      ))}
    </section>
  );
};

Paragraphs.propTypes = {
  heading: PropTypes.string,
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired
};

Paragraphs.defaultProps = {
  heading: null
};

export default Paragraphs;
