import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Paragraphs from "../components/paragraphs";
import List from "../components/list";
import CtaBlock from "../components/cta-block";

const IndividualConsultations = ({ data }) => {
  const {
    description,
    banner,
    paragraphs,
    consultationProcess,
    dietaryAreas,
    ctaBlock
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={banner.title}
        description={description}
        imagePath={banner.bannerImage.publicURL}
      />
      <Hero
        fluid={banner.bannerImage.childImageSharp.fluid}
        title={banner.title}
        blurb={banner.blurb}
      />
      <Paragraphs paragraphs={paragraphs} />
      <List
        heading={consultationProcess.heading}
        listItems={consultationProcess.processes}
        isLarge
        isColor
      />
      <List heading={dietaryAreas.heading} listItems={dietaryAreas.areas} />
      <CtaBlock
        heading={ctaBlock.heading}
        text={ctaBlock.text}
        ctaLink={ctaBlock.button.link}
        ctaName={ctaBlock.button.name}
      />
    </Layout>
  );
};

IndividualConsultations.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default IndividualConsultations;

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: { templateKey: { eq: "individual-consultations" } }
    ) {
      frontmatter {
        description
        banner {
          title
          bannerImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          blurb
        }
        paragraphs
        consultationProcess {
          heading
          processes
        }
        dietaryAreas {
          heading
          areas
        }
        ctaBlock {
          heading
          text
          button {
            name
            link
          }
        }
      }
    }
  }
`;
